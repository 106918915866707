import React from "react";

// External
import { useLocation } from "@reach/router";
import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Internal
import { breakpoints, colors, fontFamily } from "../../../theme";
import BlogCard from "../../components/BlogCard";
import Button from "../../components/form/Button";
import SplashText from "../../components/SplashText";
import { Flex } from "../../styles/global-styles";
import useMediaQuery from "../../hooks/use-media-query";

// Styles
const Section = styled(Element)`
  padding: 20px 0px 60px;

  ${props =>
    props.isLinksPage &&
    css`
      padding: 20px 0px 0px;
    `}

    background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 0;

    ${props =>
      props.isLinksPage &&
      css`
        padding: 70px 0px 0px;
      `}
  }
`;

const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;

const BlogPostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 32px;
  width: 85%;
  margin: ${props => (props.isHomepage ? "36px auto 28px" : "36px auto 72px")};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    // grid-template-columns: 1fr;
    width: 75%;
    margin: ${props => (props.isHomepage ? "120px auto 28px" : "120px auto")};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(2, 1fr);
    width: 85%;

    ${props =>
      props.variant === "wide" &&
      css`
        grid-template-columns: 1fr;
        width: 75%;
      `}
  }
  @media only screen and (min-width: 1260px) {
    grid-template-columns: repeat(3, 1fr);
    width: 90%;

    ${props =>
      props.variant === "wide" &&
      css`
        grid-template-columns: 1fr;
        width: 75%;
      `}
  }
  @media only screen and (min-width: 2000px) {
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    
    ${props =>
      props.variant === "wide" &&
      css`
        grid-template-columns: 1fr;
        width: 75%;
      `}
  }

  ${props =>
    props.isEmpty &&
    css`
      grid-template-columns: 1fr !important;
    `}
`;

const BlogCardWrapper = styled.div``;

const StyledButton = styled(Button)`
  // height: 50px;
  // width: 156px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 24px;
    padding: 21px 39px;
    margin: 60px auto;
  }
`;

const StyledAniLink = styled(AniLink)`
  text-decoration: none;
  // color: ${colors.black};
`;

const ComingSoonText = styled.span`
  text-align: center;
  display: block;
  width: 100%;
  font-size: 14px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
  }
`;

const BlogSection = ({ blogPosts, title = "Blog" }) => {
  const { pathname } = useLocation();
  const isHomepage = pathname === "/";

  const isLinksPage = pathname === "/links";

  const cardVariant = isLinksPage ? "wide" : "normal";

  // console.log({ isHomepage });

  const isSmallDesktop = useMediaQuery(
    `(min-width: ${breakpoints.desktop}) and (max-width: 1260px)`
  );

  const isVeryLargeDesktop = useMediaQuery("(min-width: 2000px)");

  // variable
  const blogPostsCountLimit = () => {
    // If homepage, then limit, else all
    if (!isHomepage) {
      return blogPosts.length;
    }
    // isMobile or isTablet: 3
    if (isSmallDesktop || isVeryLargeDesktop) {
      return 4;
    }
    return 3;
  };

  const shouldViewAllRender =
    isHomepage &&
    blogPosts.length !== 0 &&
    blogPosts.length > blogPostsCountLimit();

  return (
    <Section name="blog" as="section" isLinksPage={isLinksPage}>
      <Heading>
        <SplashText variant="peach">{title}</SplashText>
      </Heading>
      <BlogPostsGrid
        isEmpty={blogPosts.length === 0}
        isHomepage={isHomepage}
        variant={cardVariant}
      >
        {blogPosts.length === 0 && (
          <ComingSoonText>📝 Coming Soon! </ComingSoonText>
        )}
        {blogPosts.length > 0 &&
          blogPosts.slice(0, blogPostsCountLimit()).map(blogPost => (
            <BlogCardWrapper key={blogPost.id}>
              <BlogCard data={blogPost} />
            </BlogCardWrapper>
          ))}
      </BlogPostsGrid>
      {shouldViewAllRender && (
        <Flex alignCenter>
          <StyledAniLink cover bg={colors.black} to="/blog">
            <StyledButton>View All</StyledButton>
          </StyledAniLink>
        </Flex>
      )}
    </Section>
  );
};

export default BlogSection;
