import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Image({ src, alt, ...props }) {
  // console.log({ src, alt });
  // childImageSharp: {fluid: {originalName: {eq:"react-mug.jpg"}}}

  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query IMAGE_QUERY {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativePath
          }
        }
      }
    }
  `);
  // console.log({ edges });
  // const {} = response;
  /* const {
    a: {
      childImageSharp: { gatsbyImageData },
    },
  } = response; */
  /*   const {
    file: {
      childImageSharp: { gatsbyImageData: imageData },
    },
  } = useStaticQuery(graphql`
    query IMAGE_QUERY($src: String) {
      file(childImageSharp: { fluid: { originalName: { eq: $src } } }) {
        childImageSharp {
          gatsbyImageData
          fluid {
            originalName
          }
        }
      }
    }
  `); */

  /* 
  query MyQuery {
  file(childImageSharp: {fluid: {originalName: {eq:"react-mug.jpg"}}}) {
    childImageSharp {
      gatsbyImageData
      fluid {
        originalName
      }
    }
  }
}

  
  */
  /*   const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }́
  `); */

  // console.log({ imageData });
  const foundNode = edges.find(edge => edge.node.relativePath === src);
  if (!foundNode) {
    return null;
  }

  const {
    node: {
      childImageSharp: { gatsbyImageData },
    },
  } = foundNode;
  // console.log({ image });
  if (!gatsbyImageData) {
    return null;
  }

  // const getImageResponse = getImage(image.node.fluid.src);
  // return null;

  // console.log({ gatsbyImageData, alt, src });

  return <GatsbyImage image={gatsbyImageData} alt={alt} {...props} />;

  // ÷  return <GatsbyImage fluid={image.node.fluid} alt={alt} />;
}
