import React from "react";
// External
import styled, { css } from "styled-components";
import { useLocation } from "@reach/router";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Internal
import { colors, fontFamily, breakpoints } from "../../../theme";
import Button from "../form/Button";

// Components
import Image from "../common/Image";
import { Flex } from "../../styles/global-styles";

// Hooks

// State
import { isVideo } from "../../utils/helpers/file";
import useMediaQuery from "../../hooks/use-media-query";

const BlogContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 12px auto;
  cursor: pointer;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 14px auto;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    max-width: 1080px;
    ${props =>
      props.variant === "normal" &&
      css`
        cursor: initial;
        display: block;
        margin: 0;
        height: 520px;
        position: relative;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 18px;
        background: #f5f5f5;
      `}
  }
`;
const BlogImageWrapper = styled.div`
  height: 99px;
  width: 99px;
  border-radius: 12px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    height: 120px;
    width: 120px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    height: 300px;
    width: auto;
    border-radius: 18px;
    margin: 0px auto 24px;

    ${props =>
      props.variant === "wide" &&
      css`
        height: 240px;
      `}
  }
`;
const BlogContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0 6px;
  margin-left: 12px;
  width: calc(100% - 99px);
  justify-content: space-between;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: calc(100% - 120px);
    margin-left: 16px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    align-items: flex-start;
    height: ${props =>
      props.variant === "wide"
        ? "calc(520px - 300px)"
        : "calc(520px - 300px - 40px - 24px)"};

    padding: 0 28px;
    width: auto;
    margin-left: 0;
  }
`;
const BlogContent = styled.div``;
const BlogCategory = styled.span`
  display: none;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    display: block;
    font-size: 14px;
    font-family: ${fontFamily.regular};
    text-transform: capitalize;
  }
`;
const BlogHeading = styled.div`
  font-size: 14px;
  font-family: ${fontFamily.medium};
  margin: 0;

  @media only screen and (min-width: 600px) {
    font-size: 16px;
    font-family: ${fontFamily.bold};
  }
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
    font-family: ${fontFamily.bold};
  }

  /*   @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 20px;
    font-family: ${fontFamily.bold};
  } */
`;
const BlogHelperText = styled.div`
  font-size: 12px;
  font-family: ${fontFamily.regular};

  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 14px;
    font-family: ${fontFamily.regular};
  }
`;
const BlogReadMoreButton = styled(Button)`
  display: none;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${props =>
      props.cardVariant === "wide" &&
      css`
        display: inline-block;
        color: ${colors.black};
        border: 1px solid ${colors.black};
      `}
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    display: inline-block;
    color: ${colors.black};
    border: 1px solid ${colors.black};
  }
`;

const Video = styled.video`
  height: 99px;
  width: 99px;
  border-radius: 12px;
  object-fit: cover;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 120px;
    height: 120px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    width: 100%;
    height: 300px;
    border-radius: 12px;
    // box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);

    ${props =>
      props.variant === "wide" &&
      css`
        height: 240px;
      `}
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 200px;
  object-fit: cover;
  // border-radius: 12px;
  object-fit: cover;
`;

const StyledAniLink = styled(AniLink)`
  text-decoration: none;
  color: ${colors.black};
`;

const BlogCard = ({
  data: {
    frontmatter: {
      slug,
      series,
      cover_src,
      title,
      description,
      date,
      read_time,
    },
  },
  variant,
}) => {
  const { pathname } = useLocation();
  const isBlogPage = pathname === "/blog";
  const isLinksPage = pathname === "/links";
  // console.log({ isLinksPage });

  const cardVariant = isLinksPage ? "wide" : "normal";

  return (
    <BlogContainer variant={cardVariant}>
      <BlogImageWrapper variant={cardVariant}>
        {isVideo(cover_src) ? (
          <Video
            autoPlay
            muted
            loop
            src={cover_src}
            variant={cardVariant}
          ></Video>
        ) : (
          <StyledImage
            src={cover_src}
            alt={title}
            imgStyle={{ height: "100%", objectFit: "contain" }}
            variant={cardVariant}
          />
        )}
      </BlogImageWrapper>
      <BlogContentContainer variant={cardVariant}>
        <BlogContent variant={cardVariant}>
          <BlogCategory variant={cardVariant}>{series}</BlogCategory>
          <BlogHeading variant={cardVariant}>{title}</BlogHeading>
        </BlogContent>
        <Flex spaceBetween style={{ width: "100%" }}>
          <BlogHelperText variant={cardVariant}>
            {date} - {read_time} {read_time > 1 ? "mins" : "min"} read
          </BlogHelperText>
          <StyledAniLink
            cover
            bg="#000"
            to={`${isBlogPage ? "" : "/blog/"}${slug}`}
          >
            <BlogReadMoreButton
              variant="outlined"
              size="small"
              // onClick={handleReadMoreButton}
              bgColor="#000000"
              cardVariant={cardVariant}
            >
              Read More
            </BlogReadMoreButton>
          </StyledAniLink>
        </Flex>
      </BlogContentContainer>
    </BlogContainer>
  );
};

const BlogCardWrapper = props => {
  const {
    data: {
      frontmatter: { slug },
    },
  } = props;
  const { pathname } = useLocation();
  const isBlogPage = pathname === "/blog";

  const isTablet = !useMediaQuery(`(min-width: ${breakpoints.desktop} )`);
  if (isTablet) {
    return (
      <StyledAniLink
        cover
        bg="#000"
        to={`${isBlogPage ? "" : "/blog/"}${slug}`}
      >
        <BlogCard {...props} />
      </StyledAniLink>
    );
  }
  return <BlogCard {...props} />;
};
export default BlogCardWrapper;
