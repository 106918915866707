const VIDEO_EXTENSIONS = [
  "mp4",
  "webm",
  "ogv",
  "mov",
  "avi",
  "wmv",
  "flv",
  "mkv",
  "m4v",
  "3gp",
  "3g2",
];

// const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

export const getFileExtension = fileName => {
  const extension = fileName.split(".").pop();
  // console.log({ extension });
  return extension;
};

export const isVideo = fileName => {
  const extension = getFileExtension(fileName);
  return VIDEO_EXTENSIONS.includes(extension);
};
